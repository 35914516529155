import useProductOne from '@hooks/use-product-one';
import useProductTwo from '@hooks/use-product-two';
import useWhiteLabel from '@hooks/use-white-label';

import AboutClarkeHeroSection from './section';
import CoverSection from './hero';
import HowWeDoItSection from './how-we-do-it';
import MigrationsSection from './migrations';
import ScheduleSection, { ProductType } from './schedule';
import WhatWeDoSection from './what-we-do';
import { AboutEnergisaSection } from './energisa';

function AboutSectionProposalContent() {
  const { proposal: productOneProposal } = useProductOne();
  const { proposal: productTwoProposal } = useProductTwo();
  const { whiteLabelSupplier, isWhiteLabelProposal } = useWhiteLabel();

  const isRetailPath = window.location.pathname.includes('clarke-varejo');
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const pricesExpirationDate =
    productTwoProposal?.calculatorMetadata?.default?.bestRetailTraderResult?.pricesExpirationDate;

  const leadName = isManagementPath ? productOneProposal?.lead?.name : productTwoProposal?.lead?.name;

  if (leadName) {
    return (
      <>
        {isManagementPath && (
          <>
            <CoverSection leadName={leadName} />
            {!isWhiteLabelProposal && <ScheduleSection productType={ProductType.Management} />}
            <AboutClarkeHeroSection />
            {whiteLabelSupplier?.type === 'MANAGER' && <WhatWeDoSection />}
            {!isWhiteLabelProposal && <MigrationsSection />}
            <HowWeDoItSection />
          </>
        )}
        {isRetailPath && (
          <>
            {<CoverSection pricesExpirationDate={pricesExpirationDate} />}
            <ScheduleSection productType={ProductType.Retail} />
            <AboutClarkeHeroSection />
            <AboutEnergisaSection />
          </>
        )}
      </>
    );
  }
  return null;
}

export default AboutSectionProposalContent;
